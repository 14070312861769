import request from "@/api/request";

const localApi = {
    //获取本地指定设备屏幕镜像
    screen(deviceSerialNo){
        return request({
            url: '/local/getScreen/'+ deviceSerialNo,
            responseType: 'blob', // 重要：设置响应类型为blob
            method: 'get',
        })
    },
    testAction(param){
        return request({
            url: '/local/testAction/'+ param.deviceSerialNo + '/' + param.actionId,
            method: 'get',
        })
    },
}

export default localApi