<template>
  <div class="container">
    <div style="margin-top: 20px; width: 70%">
      <el-row :gutter="1">
        <el-col :span="6">
          <div>
            <el-statistic :value="allSize" title="用户人数"></el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic title="在线人数" :value="onlineSize"></el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic :value="officialSize" title="正式用户">
              <template slot="suffix">
                <span class="like">
                  <i
                    class="el-icon-star-on"
                    style="color: red"
                    v-show="!!like"
                  ></i>
                  <i class="el-icon-star-off" v-show="!like"></i>
                </span>
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic :value="cancelSize" title="注销用户数"></el-statistic>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--用户列表-->
    <div style="width: 95%; margin-top: 50px">
      <hr />
      <el-form label-width="100px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="用户名:">
              <el-input
                placeholder="请输入用户名"
                v-model="selectForm.username"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系方式：">
              <el-input
                placeholder="请输入联系方式"
                v-model="selectForm.phone"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="queryButton">查询</el-button>
          </el-col>
          <el-col :span="1">
            <el-button type="success" @click="registerUserDialog"
              >注册用户</el-button
            >
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="userList" :row-class-name="tableRowClassName">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column
          prop="account"
          label="账号"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="昵称"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="email"
          label="QQ邮箱"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="联系方式"
          width="180"
        ></el-table-column>
        <el-table-column prop="official" label="是否正式用户" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.official === '1'" type="success">是</el-tag>
            <el-tag v-else type="info">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="storageUsage"
          label="使用量"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="onlineLast"
          label="最近上线"
          width="180"
        ></el-table-column>
        <el-table-column
          label="注册日期"
          width="180"
        >
          <template slot-scope="scope">
            {{parseTime(scope.row.createdTime)}}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <el-button type="primary" size="mini">编辑</el-button>
        </el-table-column>
      </el-table>
    </div>

    <!--注册Dialog-->
    <el-dialog
      title="用户注册"
      :visible="registerDialog"
      width="40%"
      :before-close="closeRegisterDialog"
    >
      <el-form label-width="160px">
        <el-form-item label="账号：">
          <el-input
              placeholder="请输入账号"
              v-model="registerForm.account"
              maxlength="10"
              minlength="5"
              show-word-limit
              clearable
              style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称：">
          <el-input
            placeholder="请输入昵称"
            v-model="registerForm.nickName"
            maxlength="10"
            minlength="5"
            show-word-limit
            clearable
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="Email(可选):">
          <el-input
            placeholder="Email"
            v-model="registerForm.email"
            clearable
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式(可选):">
          <el-input
            placeholder="联系方式"
            v-model="registerForm.phone"
            clearable
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input
            placeholder="请输入密码"
            v-model="registerForm.password"
            type="password"
            maxlength="10"
            minlength="6"
            show-word-limit
            clearable
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：">
          <el-input
            placeholder="请确认密码"
            v-model="registerForm.checkPassword"
            type="password"
            maxlength="10"
            minlength="6"
            show-word-limit
            clearable
            style="width: 95%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="success"
          round
          style="margin: 10px; width: 300px"
          @click="registerUserMothod"
          >创建用户</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import userApi from "@/api/user";
import util from "@/util";
export default {
  name: "UserControl",
  data() {
    return {
      like: true,
      value1: 4154.564,
      allSize: 1314,
      onlineSize: 100,
      officialSize: 700,
      cancelSize: 0,
      selectForm: {
        username: "",
        phone: "",
      },
      page: {
        pageCurrent: 1,
        pageSize: 10,
      },
      userList: [],
      userListDemo: [
        {
          name: "admin",
          email: "1109643763@qq.com",
          phone: "17312521352",
          official: "1",
          storageUsage: "0.03",
          onlineLast: "2024-07-09",
          registerTime: "2024-07-09",
        },
        {
          name: "testUser",
          email: "1109643763@qq.com",
          phone: "17312521352",
          official: "1",
          storageUsage: "0.91",
          onlineLast: "2024-07-09",
          registerTime: "2024-07-09",
        },
        {
          name: "testUser1",
          email: "1109643763@qq.com",
          phone: "17312521352",
          official: "1",
          storageUsage: "0.15",
          onlineLast: "2024-07-09",
          registerTime: "2024-07-09",
        },
      ],
      registerDialog: false,
      registerForm: {
        account: "",
        nickName:'',
        email: "",
        phone: "",
        password: "",
        checkPassword: "",
      },
    };
  },
  mounted(){
    //查询列表
    this.queryButton()
  },
  methods: {
    //根据属性渲染行颜色
    tableRowClassName({ row, rowIndex }) {
      if (row.storageUsage > 0.9) {
        //存储使用量>90%
        return "warning-row";
      } else if (row.storageUsage < 0.1) {
        //存储使用量<10%
        return "success-row";
      }
      return "";
    },

    queryButton() {
      this.page.pageCurrent = 1;
      this.queryUserList();
    },
    queryUserList() {
      let param = {};
      param.username = this.selectForm.username;
      param.phone = this.selectForm.phone;
      param.pageCurrent = this.page.pageCurrent;
      param.pageSize = this.page.pageSize;

      userApi.queryUser(param).then((res) => {
        console.log("@queryUser:", res);
        if (res) {
          this.userList = res;
        }
      });
    },
    //注册用户
    registerUserDialog() {
      this.registerDialog = true;
    },
    //关闭dialog
    closeRegisterDialog() {
      this.registerDialog = false;
      this.cleanDiaLogForm();
    },
    cleanDiaLogForm() {
      this.registerForm = {
        account: "",
        nickname:'',
        email: "",
        phone: "",
        password: "",
        checkPassword: "",
      };
    },
    registerUserMothod() {
      console.log("@registerUserMothod:", this.registerForm);

      //校验两次密码输入是否一致
      if(this.registerForm.password !== this.registerForm.checkPassword){
        this.$message.error('两次密码输入不一致，请重新输入');
        return 
      }
      let userDto = {}
      userDto.account = this.registerForm.account
      userDto.nickName = this.registerForm.nickName
      userDto.phone = this.registerForm.phone
      userDto.email = this.registerForm.email
      userDto.password = this.registerForm.password

      console.log('userDto:',userDto);
      userApi.registerUser(userDto).then((res)=>{
        console.log(res)
        if(res === 200){
          //关闭dialog
          this.closeRegisterDialog()
          //刷新列表
          this.queryButton()
          this.$message({
          message: '用户注册成功',
          type: 'success'
        });
        }
      })
    },
    parseTime(target) {
      return util.parseTime(target);
    },
  },
};
</script>
<style scope>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>