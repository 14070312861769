<template>
  <div class="container">
    <!--    <div style="width: 100%; text-align: left; margin-top: 15px">-->
    <!--      <el-page-header @back="goBack" :content="jobDto.jobName">-->
    <!--      </el-page-header>-->
    <!--    </div>-->
    <div style="display: flex; justify-content: space-between; width: 100%">
      <!--屏幕展示区域-->
      <div class="leftDiv">
        <el-card class="box-card" style="width: 100%">
          <div slot="header" style="height: 30px">
            <el-select
                style="float: left; margin-left: 10px; width: 30%"
                v-model="selectDevice"
                placeholder="请选择设备"
            >
              <el-option
                  v-for="item in devices"
                  :key="item.deviceSerialNo"
                  :label="createSelectLable(item)"
                  :value="item.deviceIp ? item.deviceIp : item.deviceSerialNo"
                  :disabled="item.status !== 'DEVICE'"
              >
              </el-option>
            </el-select>
            <el-select
                style="float: left; margin-left: 10px; width: 15%"
                v-model="imageProportion"
                placeholder="比例"
            >
              <el-option
                  v-for="item in imageProportions"
                  :key="item.lable"
                  :label="item.lable"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
                style="float: right"
                type="primary"
                size="medium"
                :loading="imageUrlButtonLoading"
                @click="getImages()"
            >获取当前屏幕镜像</el-button>
          </div>
          <div style="width: 100%">
            <img v-if="imageUrl" :src="imageUrl" alt="Base64 Image" :style="{'max-width': imageProportion, 'height': 'auto'}"/>
          </div>
        </el-card>
      </div>
      <!--任务流程主体-->
      <div class="rightDiv">
        <el-card
            class="box-card"
            style="background-color: #ebeef5; width: 100%"
        >
          <div slot="header" class="clearfix">
            <el-radio-group v-model="size">
              <el-radio label="">默认</el-radio>
              <el-radio label="medium">中等</el-radio>
              <el-radio label="small">小型</el-radio>
              <el-radio label="mini">超小</el-radio>
            </el-radio-group>
          </div>
          <div
              v-for="(actionDto, index) in actionList"
              :key="index"
              class="text item"
              style="margin-top: 0px"
          >
            <el-descriptions :column="4" :size="size" border>
              <!--              <template slot="extra">-->
              <!--                <el-button-->
              <!--                    type="primary"-->
              <!--                    size="small"-->
              <!--                    @click="editClick(actionDto)"-->
              <!--                >操作</el-button-->
              <!--                >-->
              <!--              </template>-->
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  序号
                </template>
                <el-tag v-show="serialNoIndex !== index" size="small" type="warning" @click="changeSerialNo(actionDto,index)">{{actionDto.serialNo}}</el-tag>
                <el-input v-show="serialNoIndex === index" v-model="changeSerialNoed" style="width: 60px;margin-right: 5px"></el-input>
                <el-button v-show="serialNoIndex === index" size="mini" style="margin-left: 5px" @click="saveChangeSerialNo(actionDto)">保存</el-button>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-wallet"></i>
                  动作名称
                </template>
                {{ actionDto.actionName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  动作类型
                </template>
                <el-tag size="small">{{ actionDto.actionType }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-edit"></i>
                  操作
                </template>
                <el-button
                    style="margin-left: 20px"
                    type="info"
                    size="small"
                    @click="testActionClick(actionDto)"
                    :loading="testButtonLoading === actionDto.id"
                >测试</el-button>
                <el-button
                    style="margin-left: 20px"
                    type="primary"
                    size="small"
                    @click="editClick(actionDto)"
                >编辑</el-button>
                <el-button
                    style="margin-left: 15px"
                    type="danger"
                    size="small"
                    @click="removeClick(actionDto)"
                >删除</el-button>
              </el-descriptions-item>
              <el-descriptions-item v-if="actionDto.actionType === 'TEMPLATE'">
                <template slot="label">
                  <i class="el-icon-picture-outline"></i>
                  模版图像
                </template>
                <!--图像展示组件-->
                <image-view v-show="actionDto.actionType === 'TEMPLATE'" :ref="'imageViewCom_' + actionDto.fileId" :file-id="actionDto.fileId"></image-view>
              </el-descriptions-item>
              <el-descriptions-item v-if="actionDto.actionType === 'POINT_LOOP'">
                <template slot="label">
                  <i class="el-icon-refresh-right"></i>
                  循环次数
                </template>
                {{actionDto.loopCount}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  备注
                </template>
                {{ actionDto.actionDec }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div>
            <el-button type="success" @click="viewJobActionDialog"
            >新增动作</el-button
            >
          </div>
        </el-card>
      </div>
    </div>
    <!--终端编辑界面-->
    <el-dialog
        title="终端信息"
        :visible="TerminalDialogVisible"
        width="60%"
        :before-close="TerminalDialogClose"
    >
      <el-descriptions :column="3" border>
        <el-descriptions-item
            label="归属用户"
            label-class-name="my-label"
            content-class-name="my-content"
        >kooriookami</el-descriptions-item
        >
        <el-descriptions-item label="用户状态">正式用户</el-descriptions-item>
        <el-descriptions-item label="可用命令">
          <el-tag size="small" class="custom-tag">{{ adbCommandCount }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="终端管理设备">
          <el-tag size="small" class="custom-tag">{{ deviceCount }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="在线设备">
          <el-tag type="success" size="small">{{ onlineDeviceCount }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="新设备">
          <el-button @click="TerminalDialogVisibleInner = true" size="mini"
          >添加设备</el-button
          >
        </el-descriptions-item>
      </el-descriptions>
      <el-table :data="devices" style="width: 100%; margin-top: 30px">
        <el-table-column label="序列号" prop="deviceSerialNo"></el-table-column>
        <el-table-column label="名称" prop="deviceName"> </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 'ONLINE'" type="success"
            >在线</el-tag
            >
            <el-tag v-if="scope.row.status === 'RUN'" type="primary"
            >运行中</el-tag
            >
            <el-tag v-if="scope.row.status === 'OFFLINE'" type="info"
            >离线</el-tag
            >
            <el-tag v-if="scope.row.status === 'DISABLE'" type="danger"
            >禁用</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.status === 'ONLINE'"
                type="primary"
                size="mini"
            >执行命令</el-button
            >
            <el-button
                v-else-if="scope.row.status === 'RUN'"
                type="danger"
                size="mini"
            >停止任务</el-button
            >
            <el-button
                v-else-if="scope.row.status === 'OFFLINE'"
                type="info"
                size="mini"
            >设备上线</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--添加设备， dialog-->
      <el-dialog
          width="40%"
          :visible="TerminalDialogVisibleInner"
          title="添加设备"
          :before-close="TerminalDialogCloseInner"
          append-to-body
      >
        <el-form label-width="100px">
          <el-row :gutter="1">
            <el-col :span="9">
              <el-form-item label="IP:">
                <el-input v-model="addDevice.deviceIp"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="端口：">
                <el-input v-model="addDevice.devicePort"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-button
                  style="margin-left: 70px"
                  type="primary"
                  :loading="addDevice.loading"
                  @click="connectDevice"
              >连接设备</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </el-dialog>
    <el-dialog
        :visible="action.viewJobAction"
        width="40%"
        :before-close="viewJobActionDiaLogCloseHandle"
    >
      <el-form label-width="150px">
        <el-row :gutter="3">
          <el-col :span="11">
            <el-form-item label="名称:">
              <el-input v-model="action.form.actionName" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="类型:">
              <el-select v-model="action.form.actionType" placeholder="请选择">
                <el-option
                    v-for="(item, index) in actionTypeList"
                    :key="index"
                    :label="item.name"
                    :value="item.actionType"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="3" v-show="action.form.actionType === 'TEMPLATE'">
          <el-col :span="11">
            <el-form-item label="阈值:">
              <el-tooltip class="item" effect="dark" content="“模版图像的对角线长度”与“屏幕镜像的最长边”的比值" placement="right">
                <el-input v-model="action.form.threshold"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="备注:">
              <el-input type="textarea" :rows="2" v-model="action.form.actionDec" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!--文件上传组件-->
          <div v-show="action.form.actionType === 'TEMPLATE'">
            <el-upload
                ref="upload"
                action="#"
                :limit="1"
                list-type="picture-card"
                :http-request="picUpload"
                :file-list="action.form.picList"
                :auto-upload="false"
                :on-success="handleAvatarSuccess"
                :on-change="handlePicChange"
                :before-upload="beforePicUpload"
                :on-preview="handlePictureCardPreview"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!--文件展示dialog-->
            <el-dialog
                :visible="action.form.dialogPictureCardPreview"
                :before-close="closePictureCardPreview"
                append-to-body
            >
              <img width="100%" :src="action.form.viewBigUrl" alt="" />
            </el-dialog>
          </div>
          <div v-show="action.form.actionType === 'FIXED'">
            <el-row>
              <el-col :span="10">
                <el-form-item label="命令:">
                  <el-select v-model="action.form.command" placeholder="请选择">
                    <el-option
                        v-for="(item, index) in fixedCommandList"
                        :key="index"
                        :label="item.commandName"
                        :value="item.command"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-show="action.form.actionType === 'POINT' || action.form.actionType === 'POINT_LOOP'">
            <el-row>
              <el-col :span="10">
                <el-form-item label="命令:">
                  <el-select v-model="action.form.commandTemp" @change="editCommandChang" placeholder="请选择">
                    <el-option
                        v-for="(item, index) in pointCommandList"
                        :key="index"
                        :label="item.commandName"
                        :value="item.command"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="3" v-show="action.form.actionType === 'POINT_LOOP'">
              <el-col :span="11">
                <el-form-item label="循环:">
                  <el-input v-model.number="action.form.loopCount"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="action.form.actionType === 'POINT_LOOP'">
              <el-col :span="10">
                <el-form-item label="间隔(秒):">
                  <el-input v-model.number="action.form.waitTime"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-show="(action.form.actionType === 'POINT' || action.form.actionType === 'POINT_LOOP') && action.form.commandTemp">
            <el-form-item label="坐标:">
              <el-col :span="10" style="margin: 5px" v-for="(item,index) in action.form.paramList" :key="index">
                <el-input v-model="item.value">
                  <template slot="prepend">{{item.name}}:</template>
                </el-input>
              </el-col>
            </el-form-item>
          </div>
          <el-button
              style="width: 50%; margin-top: 30px"
              type="success"
              @click="createAction"
          >创建动作</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import jobListApi from "@/api/jobList";
import deviceApi from "@/api/device";
import { createAdbCommand } from "@/api/commandEdit/createCommand";
import { analysisAdbCommand } from "@/api/commandEdit/analysisCommand";
import commandEditApi from "@/api/commandEdit";
import imageView from "./ImageView.vue";
import componentsJS from './../api/components'
import localApi from "@/api/local";
import actionApi from "@/api/action";
export default {
  name: "ViewAndEditJob",
  components: { imageView },
  created() {
    this.jobId = this.$route.query.jobId;
  },
  mounted() {
    //加载当前用户所有设备
    this.queryDevices();
    //根据任务Id加载任务动作列表
    this.queryJobAction();
    //固定命令&坐标命令加载
    this.queryFixedPointCommandList();
  },
  watch:{
    //当命令变化时，动态渲染参数列表
    'action.form.commandTemp'(commandTemp,oldVal){
      //console.log('newVal',commandTemp)
      if (!commandTemp || (this.action.form.actionType !== 'POINT' && this.action.form.actionType !== 'POINT_LOOP')){
        return
      }
      // 定义一个正则表达式来匹配${}内的内容
      const regex = /\$\{([^}]+)\}/g
      const matches = commandTemp.match(regex);
      if (matches && matches.length >= 2){
        let paramList = []
        for (let i = 0; i < matches.length; i++) {
          let paramName = matches[i].replace(/\$\{|\}/g, '')
          if (paramName !== "deviceSerialNo"){
            let paramItem = {}
            paramItem.name = paramName
            paramItem.value = ''
            paramList.push(paramItem)
          }
        }
        //this.action.form.paramList = paramList
        //如果命令字段不为空，那么是回显动作
        this.action.form.paramList = analysisAdbCommand(paramList,commandTemp,this.action.form.command)
      }else {
        this.$message.error("参数提取异常")
      }
      //console.log("matches:",matches)
      //console.log("paramList:",this.action.form.paramList)
    }
  },
  data() {
    return {
      jobId: "",
      jobDto: {},
      size: "",
      //动作列表
      actionList: [],
      actionTypeList: [
        { name: "固定命令", dec: "固定命令", actionType: "FIXED", seriaNo: 1 },
        { name: "坐标命令", dec: "坐标命令", actionType: "POINT", seriaNo: 2 },
        { name: "坐标命令(循环)", dec: "坐标命令(循环)", actionType: "POINT_LOOP", seriaNo: 3 },
        {
          name: "模版命令",
          dec: "模版命令",
          actionType: "TEMPLATE",
          seriaNo: 4,
        },
      ],
      //固定命令列表
      fixedCommandList: [],
      //坐标命令列表
      pointCommandList: [],
      //选中的设备序列号(无线设备是IP)
      selectDevice: "",
      //设备图像展示
      imageUrl: "",
      //获取图像按钮loading
      imageUrlButtonLoading: false,
      //测试按钮loading状态
      testButtonLoading: 0,
      //设备列表
      devices: [],
      //设备镜像展示比例(默认55%)
      imageProportion:'55%',
      imageProportions:[{lable:'35%',value:'35%'},{lable:'45%',value:'45%'},{lable:'50%',value:'50%'},{lable:'60%',value:'60%'},{lable:'75%',value:'75%'},{lable:'100%',value:'100%'}],
      //当前用户可用的ADB命令集合
      adbCommands: [],
      TerminalDialogVisible: false,
      TerminalDialogVisibleInner: false,
      addDevice: {
        deviceIp: "",
        devicePort: "",
        loading: false,
      },
      //编辑动作
      action: {
        viewJobAction: false,
        viewJobActionType: '', //新增 add 编辑 edit
        form: {
          //编辑时，附件展示框
          dialogPictureCardPreview: false,
          id: "",
          fileId: 0,
          actionName: "",
          actionType: "",
          actionDec: "",
          paramList:[],
          //命令模版
          commandTemp: "",
          //命令
          command:"",
          //循环命令循环次数
          loopCount:2,
          //循环命令等待时长
          waitTime:3,
          //动作序号
          serialNo:0,
          picList: [],
          //放大展示图片Url
          viewBigUrl: '',
          //文件校验标记
          uploadcheckFlag: true,
          //图像匹配阈值(默认10%)
          threshold:10,
          checkMsg: "",
        },
      },
      //正在编辑动作序号的Index
      serialNoIndex: -1,
      //调整后的序号
      changeSerialNoed: 0
    };
  },
  computed: {
    //当前终端设备计数
    deviceCount: function () {
      return this.devices.length;
    },
    //在线设备计数
    onlineDeviceCount: function () {
      const val = this.devices.filter((item) => item.status === "ONLINE");
      return val ? val.length : 0;
    },
    //可用的命令行数
    adbCommandCount() {
      return this.adbCommands.length;
    },
  },
  methods: {
    queryJobAction() {
      let param = {};
      param.jobId = this.jobId;
      jobListApi.selActionByJobId(param).then((res) => {
        if (res && res.code === 200) {
          //console.log('列表加载：',res)
          this.actionList = res.list;
        } else {
          this.$message.error("数据详细加载失败");
        }
      });
    },
    createSelectLable(device){
      let blankSpace = '-'
      if(device.deviceName.length < 10){
        blankSpace = blankSpace.repeat(10 - device.deviceName.length);
      }
      return device.deviceName + ' ' + blankSpace + '- ' + device.deviceSerialNo

    },
    //加载固定命令及坐标命令
    queryFixedPointCommandList() {
      let param = {};
      commandEditApi.sel(param).then((res) => {
        if (res && res.code === 200) {
          this.fixedCommandList = res.list.filter(commandDto => commandDto.type === 'FIXED');
          this.pointCommandList = res.list.filter(commandDto => commandDto.type === 'POINT');
          //console.log("fixedCommandList----:", this.fixedCommandList);
          //console.log("pointCommandList----:", this.pointCommandList);
        } else {
          this.$message.error("命令列表加载失败");
        }
      });
    },
    queryDevices() {
      deviceApi.selDevices().then((res) => {
        //console.log("selDevices:", res);
        if (res && res.code === 200) {
          this.devices = res.list;
        }
      });
    },
    getImages() {
      if(!this.selectDevice){
        this.$message({
          type:'info',
          message:'请选择设备'
        })
        return
      }
      //按钮加载状态
      this.imageUrlButtonLoading = true
      localApi.screen(this.selectDevice).then((res)=>{
        let blob = new Blob([res], { type: "image/png" });
        this.imageUrl = URL.createObjectURL(blob);
        this.imageUrlButtonLoading = false
      }).catch((e)=>{
        console.log('请求异常：',e)
        this.imageUrlButtonLoading = false
      })
    },
    //获取图片流
    getImageStream(waitExecuteCommandId){
      let param = {}
      param.id = waitExecuteCommandId
      jobListApi.getImageStream(param).then((res)=>{
        console.log('getImageStream:',res,waitExecuteCommandId)
        if (res.code === 200 && res.msg){
          this.imageUrl = res.msg
        }else {
          console.log('等待终端任务执行完成')
        }
      })
    },
    goBack() {
      //返回任务列表页面
      this.$router.push("JobList");
    },
    //编辑终端(可以向终端发送命令)
    editTerminal() {
      //加载当前用户可用的ADB命令
      deviceApi.adbCommandList().then((res) => {
        if (res && res.code === 200) {
          this.adbCommands = res.list;
        }
      });
      this.TerminalDialogVisible = true;
    },
    //关闭dialog
    TerminalDialogClose() {
      this.TerminalDialogVisible = false;
    },
    TerminalDialogCloseInner() {
      this.TerminalDialogVisibleInner = false;
      this.addDevice = {
        deviceIp: "",
        devicePort: "",
        loading: false,
      };
    },
    //连接设备
    connectDevice() {
      if (!this.addDevice.deviceIp) {
        this.$message({
          type: "warning",
          message: "请完善设备IP",
        });
        return false;
      }
      if (!this.addDevice.devicePort) {
        this.$message({
          type: "warning",
          message: "请完善设备端口",
        });
        return false;
      }
      console.log(this.addDevice);
      let param = {};
      param.deviceSerialNo = "Terminal";
      param.id = 2;
      param.paramList = [
        { ip: this.addDevice.deviceIp },
        { port: this.addDevice.devicePort },
      ];
      deviceApi.connectDevice(param).then((res) => {
        //console.log("@Res:", res);
        if (res && res.code === 200) {
          this.addDevice.loading = true;
        } else {
          this.$message.error("命令发布失败: " + res.msg);
        }
      });
    },
    //修改任务序号
    changeSerialNo(actionDto, index){
      this.serialNoIndex = index
      this.changeSerialNoed = actionDto.serialNo
      //console.log(actionDto,index)
    },
    async saveChangeSerialNo(actionDto){
      let param = {}
      param.jobId = actionDto.jobId
      param.id = actionDto.id
      param.serialNo = this.changeSerialNoed

      //请求调整序号
      await actionApi.changeSerialNo(param).then((res)=>{
        console.log(res)
        if(res && res.code === 200){
          this.$message.success("序号更新成功")
          this.queryJobAction();
        }else {
          this.$message.error("序号更新失败")
        }
      })
      this.changeSerialNoed = 0
      this.serialNoIndex = -1
    },
    //测试动作
    testActionClick(val){
      if (!this.selectDevice){
        this.$message.info('请选择设备')
        return
      }
      this.testButtonLoading = val.id
      let param = {}
      param.deviceSerialNo = this.selectDevice //选中设备序列号
      param.actionId = val.id //动作id
      localApi.testAction(param).then((res)=>{
        //console.log('res:===',res)
        if (res && res.code === '200'){
          this.$message.success(res.msg)
        }else {
          this.$message.error(res.msg)
        }
        this.testButtonLoading = 0
      })
    },
    //打开编辑页面
    editClick(val) {
      //console.log('editClick:',val)
      this.action.viewJobActionType = 'edit'
      //数据回显
      this.action.form.id = val.id;
      this.action.form.actionName = val.actionName;
      this.action.form.actionDec = val.actionDec;
      this.action.form.actionType = val.actionType;
      this.action.form.threshold = val.threshold;
      this.action.form.commandTemp = val.commandTemp;
      this.action.form.command = val.command;
      this.action.form.fileId = val.fileId
      this.action.form.serialNo = val.serialNo
      this.action.form.loopCount = val.loopCount
      this.action.form.waitTime = val.waitTime


      //模版回显
      let param = {}
      param.id = val.fileId;
      componentsJS.getImage(param).then((res) => {
        //console.log('imageRes:',res)
        let blob = new Blob([res], { type: "image/png" });
        this.action.form.blob = blob
        //dialog框文件回显
        let item = {}
        item.name = 'fileName_' + val.fileId
        item.url = URL.createObjectURL(blob);
        this.action.form.picList.push(item)
        //放大展示时的URL
        this.action.form.viewBigUrl = URL.createObjectURL(blob);
      });

      this.action.viewJobAction = true;
    },
    //动作移除
    removeClick(row){
      let param = {}
      param.id = row.id
      jobListApi.removeJobAction(param).then((res)=>{
        //console.log('removeJobAction:',res)
        if(res && res.code === 200){
          this.$message({
            type:"success",
            message:'动作删除成功'
          })
          //刷新列表
          this.queryJobAction()
        }else {
          this.$message.error('动作删除失败:',res.msg)
        }
      })
    },
    //动作新增
    viewJobActionDialog() {
      this.action.viewJobActionType = 'add'
      this.action.viewJobAction = true;
    },
    //坐标命令变化时触发
    editCommandChang(val){
      //console.log('val:',val)
      this.action.form.command = val
    },
    //新增Dialog关闭
    viewJobActionDiaLogCloseHandle() {
      this.action.viewJobAction = false;
      this.action.viewJobActionType = ''
      this.action.form = {
        //编辑时，附件展示框
        dialogPictureCardPreview: false,
        id: "",
        fileId: 0,
        actionName: "",
        actionType: "",
        actionDec: "",
        //参数列表
        paramList: [],
        commandTemp:"",
        command: "",
        picList: [],
        //放大展示图片Url
        viewBigUrl: '',
        //文件校验标记
        uploadcheckFlag: true,
        //动作序号
        serialNo:0,
        //循环次数默认2
        loopCount:2,
        //循环命令等待时长
        waitTime:3,
        //模版匹配阈值，默认是10
        threshold: 10,
        checkMsg: "",
      };
    },
    closePictureCardPreview() {
      this.action.form.dialogPictureCardPreview = false;
    },
    //展示图片
    handlePictureCardPreview() {
      this.action.form.dialogPictureCardPreview = true;
    },
    handlePicChange(file, fileList) {
      //console.log("size:", file.size);
      //console.log(file.size > 103000);
      //限制文件大小
      if (file.size > 1000000) {
        this.$message.error("上传文件过大，请选择100K以下的文件");
        this.action.form.uploadcheckFlag = false;
        this.action.form.checkMsg = "上传文件过大，请选择100K以下的文件";
      }
    },
    //图像上传前的校验动作
    beforePicUpload(file) {},
    //图像上传
    picUpload(file) {
      //console.log("图像上传动作",file.file);
      const form = new FormData();
      form.append("id", this.action.form.id); //动作ID
      form.append("jobId", this.jobId); //动作ID
      form.append("file", file.file);
      jobListApi.uploadTemplate(form).then((res) => {
        if (res && res.code !== 200) {
          this.$message.error(
              "模版图像上传失败！请将错误截图，反馈给管理员协助您排除故障:" +
              res.msg
          );
        } else {
          console.log("模版图片上传成功");
          //只有更新时，才会触发这个动作， 新增时不能触发，因为新增组件未初始化
          if (this.action.viewJobActionType === 'edit'){
            //定点更新动作模版图像
            let refObj = 'imageViewCom_' + this.action.form.fileId + ''
            //console.log(refObj)
            //console.log(this.$refs[refObj])
            this.$refs[refObj][0].getImages();
          }
          //重置form表单
          this.viewJobActionDiaLogCloseHandle();
          //刷新列表
          this.queryJobAction();
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log("handleAvatarSuccess");
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    //创建动作
    createAction() {
      let param = {};
      param.jobId = this.jobId;
      param.actionName = this.action.form.actionName;
      param.actionDec = this.action.form.actionDec;
      param.actionType = this.action.form.actionType;

      //判断是更新还是新增
      if (this.action.form.id) {
        param.id = this.action.form.id;
        param.serialNo = this.action.form.serialNo
      }

      //参数校验
      if (!this.action.form.actionName || !this.action.form.actionType || !this.jobId) {
        this.$message.error("请完善动作参数");
        return false;
      }
      //如果为循环命令，那么循环次数不能为空，且限制在999之下
      if (this.action.form.actionType && this.action.form.actionType === 'POINT_LOOP'){
        if (this.action.form.loopCount < 2 || this.action.form.loopCount > 999){
          this.$message.error('循环次数需要控制在 2-999 之间')
          return false;
        }
        if (this.action.form.waitTime < 1 || this.action.form.waitTime > 999){
          this.$message.error('循环间隔应控制在 1-999 秒之间')
          return false;
        }
        param.loopCount = this.action.form.loopCount //循环次数
        param.waitTime = this.action.form.waitTime //循环间隔
      }

      //如果是坐标命令，那么坐标参数不能为空
      if (this.action.form.actionType === "POINT" || this.action.form.actionType === "POINT_LOOP") {
        if (this.action.form.paramList.length === 0) {
          this.$message.error("请完善坐标参数");
          return false;
        }else if (!this.action.form.commandTemp){
          this.$message.error("命令模版为空！");
          return false;
        }else {
          //验证每个参数是否都有值
          for (let i = 0; i < this.action.form.paramList.length; i++) {
            let paramItem = this.action.form.paramList[i]
            if (!paramItem.value){
              this.$message.error("请将坐标参数补充完整");
              return false;
            }
          }
        }
        //将模版与参数处理成命令
        param.command = createAdbCommand(this.action.form.commandTemp,this.action.form.paramList);
        param.commandTemp = this.action.form.commandTemp
      }

      //固定命令直接赋值
      if (this.action.form.actionType === "FIXED") {
        param.command = this.action.form.command;
      }

      //模版匹配命令创建时，阈值不能为0
      if (this.action.form.actionType === "TEMPLATE") {
        if (!this.action.form.threshold){
          this.$message.error("请设置模版阈值");
          return false;
        }else {
          param.threshold = this.action.form.threshold;
        }
      }

      //命令不能为空
      if (!param.command && param.actionType !== "TEMPLATE") {
        this.$message.error("命令不能为空");
        return false;
      }

      //模版上传时，确认文件校验状态
      if (!this.action.form.uploadcheckFlag) {
        this.$message.error(this.action.form.checkMsg);
        return false;
      }

      //注册动作
      jobListApi.createJobAction(param).then((res) => {
        //console.log("res:", res);
        if (res && res.code === 200) {
          this.$message({
            type: "success",
            message: "动作添加完成",
          });
          //如果是模版匹配动作需要上传模版
          if (this.action.form.actionType === "TEMPLATE") {
            //获取本次操作的动作数据id，并根据这个id进行模版信息操作(重要, 这个位置主要是为了解决新增动作时，动作id没有初始化问题)
            this.action.form.id = parseInt(res.msg)
            //提交文件
            this.$refs.upload.submit()
          }
          //重新加载列表
          this.viewJobActionDiaLogCloseHandle();
          //刷新列表
          this.queryJobAction();
        } else {
          this.$message.error("动作添加失败, code:" + res);
        }
      });
    },
    handlePreview(file) {
      console.log("file:", file);
    },
  },
};
</script>
<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.my-label {
  background: #e1f3d8;
}

.my-content {
  background: #fde2e2;
}
.custom-tag:hover {
  cursor: pointer;
}
.leftDiv {
  position: fixed; /* 设置为固定定位 */
  left: 20px; /* 距离页面右边20px */
  top: 70px; /* 距离页面顶部20px，根据需要调整 */
  /* 其他样式，如宽度、背景等 */
  width: 42%; /* 示例宽度 */
}
.rightDiv {
  position: fixed;
  width: 55%;
  right: 20px;
  top: 70px;
  overflow-y: auto;
  height: 95vh;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>