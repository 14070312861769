const runModelCodeApi = {
    //动作码值转换
    turnCode(runModel,cron){
        //手动模式
        if (runModel === 'HAND'){
            return 0
        }
        //自动模式
        if (runModel === 'AUTO'){
            return -1
        }
        //cron模式
        if (runModel === 'CRON'){
            let cronArr = cron.split(' ')
            for (let i = 5; i >=0 ; i--) {
                if (cronArr[i] !== '*'){
                    return i + 1
                }
            }
            return cronArr
        }
    },
    turnEnum(typeCode){
        if (Number(typeCode) < 0){
            return 'AUTO'
        }
        if (Number(typeCode) === 0){
            return 'HAND'
        }
        if (Number(typeCode) > 0){
            return 'CRON'
        }
    }
}
export default runModelCodeApi