import request from "@/api/request";


const userApi = {
    //查询用户列表
    queryUser(data){
        return request({
            url: '/api/user/selUser',
            method: 'post',
            data
        })
    },

    //注册用户
    registerUser(data){
        return request({
            url: '/api/user/registerUser',
            method: 'post',
            data
        })
    },
}

export default userApi