import request from "@/api/request";

const deviceApi = {
    //查询当前用户所有设备
    selDevices() {
        return request({
            url: '/api/device/selDevices',
            method: 'post',
        })
    },
    //编辑设备信息
    editDevice(data){
        return request({
            url: '/api/device/editDevice',
            method: 'post',
            data
        })
    },
    //加载当前用户可用的adb命令
    adbCommandList() {
        return request({
            url: '/api/device/adbCommandList',
            method: 'post',
        })
    },
    //连接指定设备
    connectDevice(data){
        return request({
            url: '/api/device/addCommandToWaitExecution',
            method: 'post',
            data
        })
    },
    //触发设备任务开关
    touchSwitch(data){
        return request({
            url: '/api/device/touchSwitch',
            method: 'post',
            data
        })
    },
}

export default deviceApi