//引入axios
import axios from "axios";

//引入进度条
import nprogress from 'nprogress'

//引入进度条样式
import 'nprogress/nprogress.css'


//创建axios实例，并设置基本参数
const requests = axios.create({
    baseURL: "/",
    timeout: 5000
})


//请求拦截器
//将token封装进请求头
requests.interceptors.request.use((config) => {
    //网络请求开始，开启进度条
    nprogress.start()

    //config是配置对象，当中的header对象很重要
    const token = localStorage.getItem('Authorization')
    if (token) {
        // 如果token存在，则将token添加到请求头中  
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config
})

//响应拦截器
requests.interceptors.response.use(
    (res) => {
        //网络请求结束，关闭进度条
        nprogress.done()
        //服务器成功的回调函数
        return res.data
    },
    (err) => {
        //网络请求结束，关闭进度条
        nprogress.done()

        //console.log('err:', err.response.status)
        //redis服务器超时处理，目前只是发现status为空，所以这个位置只拦截一下status
        if (err.response && err.response.status) {
            //服务器响应失败的回调函数
            if (err.response.status === 401) {
                console.log('401，重定向到login')
                //清除token， 不然这个位置即使个死循环
                localStorage.removeItem('Authorization')
                //暂时先这么写，可以使用，之后考虑为什么router不行
                window.location.href = "/login";
            } else {
                // 其他错误处理逻辑
                return Promise.reject(err);
            }
        } else {
            console.log('@Redis error !!!');
            return false
        }
    })

//对外暴露配置好的axios
export default requests