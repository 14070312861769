<template>
  <div class="container">
    <div
        style="
        margin: 10px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input
              placeholder="设备识别号"
              v-model="keyWord.deviceSerialNo"
              clearable
          ></el-input>
        </el-col>
        <el-col :span="5">
          <el-input
              placeholder="设备名称"
              v-model="keyWord.deviceName"
              clearable
          ></el-input>
        </el-col>
        <el-col :span="5">
          <el-select v-model="keyWord.autoRun" placeholder="运行控制">
            <el-option
                v-for="item in constant.RunStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select v-model="keyWord.autoCircle" placeholder="循环控制">
            <el-option
                v-for="item in constant.CircleStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div style="height: 80vh">
      <el-table
          :data="viewDevices"
          height="100%"
          border
          style="width: 100%"
          :row-class-name="tableRowClassName"
      >
        <el-table-column prop="deviceSerialNo" label="设备识别号" width="180">
          <template slot-scope="scope">
            <span
                class="clickable-span"
                @click="showEditDialog(scope.row, 'deviceName')"
            >{{ scope.row.deviceSerialNo }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" width="180">
          <template slot-scope="scope">
            <span
                class="clickable-span"
                @click="showEditDialog(scope.row, 'deviceName')"
            >{{ scope.row.deviceName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="authKey" label="设备秘钥" width="180">
          <template slot-scope="scope">
            <span
                class="clickable-span"
                @click="showEditDialog(scope.row, 'authKey')"
            >{{ scope.row.authKey }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="deviceIp" label="IPV4" width="180">
        </el-table-column>
        <el-table-column prop="status" label="设备状态" width="180">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 'DEVICE'" type="success"
            >在线</el-tag
            >
            <el-tag v-else-if="scope.row.status === 'OFFLINE'" type="danger"
            >离线</el-tag
            >
            <el-tag v-else type="info">未知状态</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="onlineTime" label="最近上线时间" width="180">
          <template slot-scope="scope">
            {{ parseTime(scope.row.onlineTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="autoRun" label="运行模式" width="180">
          <template slot-scope="scope">
            <el-button @click="showRunModelDialog(scope.row)">{{scope.row.runModel}}</el-button>
            <!--            <el-button-->
            <!--                v-if="scope.row.autoRun === 'HAND'"-->
            <!--                size="mini"-->
            <!--                type="primary"-->
            <!--                @click="changeAutoStatus(scope.row, 'AUTORUN')"-->
            <!--            >手动</el-button>-->
            <!--            <el-button-->
            <!--                v-else-->
            <!--                size="mini"-->
            <!--                type="success"-->
            <!--                @click="changeAutoStatus(scope.row, 'AUTORUN')"-->
            <!--            >自动</el-button>-->
          </template>
        </el-table-column>
        <el-table-column prop="autoCircle" label="循环运行" width="180">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.autoCircle === 'HAND'"
                size="mini"
                type="info"
                @click="changeAutoStatus(scope.row, 'AUTOCIRCLE')"
            >否</el-button>
            <el-button
                v-else
                size="mini"
                type="success"
                @click="changeAutoStatus(scope.row, 'AUTOCIRCLE')"
            >是</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="autoCircle" width="180">
          <template slot-scope="scope">
            <el-button
                :disabled="scope.row.runModel === 'AUTO' || scope.row.runModel === 'CRON' || scope.row.status !== 'DEVICE'"
                size="mini"
                type="warning"
                @click="touchSwitch(scope.row)"
            >开始任务</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :visible="editDialog.showDiaLog"
        width="50%"
        :before-close="handleEditDialogClose"
    >
      <h3>设备任务配置</h3>
      <hr>
      <div style="text-align: center;margin: 10px">
        <el-transfer
            style="text-align: left; display: inline-block"
            v-model="editDialog.deviceJobList"
            filterable
            :render-content="renderFunc"
            :titles="['任务列表', '设备']"
            :button-texts="['移除任务', '添加任务']"
            :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }"
            target-order
            @change="handleChange"
            :data="jobList"
        >
        </el-transfer>
        <div style="margin:20px">
          <el-button :loading="editDialog.saveDeviceJobButtonLoading" size="medium" type="primary" @click="saveDeviceJob">保存操作</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible="runModel.showDialog"
        width="35%"
        :before-close="handleRunModelDialogClose"
    >
      <h3>运行模式</h3>
      <hr>
      <!--设置任务执行时间-->
      <el-radio-group v-model="runModel.type" style="margin: 20px">
        <el-radio-button label="-1">自动</el-radio-button>
        <el-radio-button label="0">手动</el-radio-button>
        <el-radio-button label="5">月</el-radio-button>
        <el-radio-button label="4">日</el-radio-button>
        <el-radio-button label="3">时</el-radio-button>
        <el-radio-button label="2">分</el-radio-button>
        <el-radio-button label="1">秒</el-radio-button>
      </el-radio-group>
      <div style="margin: 10px">
        <el-input v-show="runModel.type >= 5" style="width: 90px" v-model="runModel.mounth" placeholder="月"></el-input> <span v-show="runModel.type >= 5"> - </span>
        <el-input v-show="runModel.type >= 4" style="width: 90px" v-model="runModel.day" placeholder="日"></el-input> <span v-show="runModel.type >= 4"> , </span>
        <el-input v-show="runModel.type >= 3" style="width: 90px" v-model="runModel.hour" placeholder="时"></el-input> <span v-show="runModel.type >= 3"> : </span>
        <el-input v-show="runModel.type >= 2" style="width: 90px" v-model="runModel.minute" placeholder="分"></el-input> <span v-show="runModel.type >= 2"> : </span>
        <el-input v-show="runModel.type >= 1" style="width: 90px" v-model="runModel.second" placeholder="秒"></el-input>
      </div>
      <div v-show="cron && runModel.type > 0">{{cron}}</div>
      <div style="margin-top: 30px">
        <el-button type="success" @click="changeAutoStatus(runModel.row,'RUNMODEL')">保存编辑</el-button>
      </div>
    </el-dialog>
    <el-button type="danger">{{runModel.type}}</el-button>
  </div>
</template>
<script>
import deviceApi from "@/api/device/index";
import jobListApi from "@/api/jobList"
import util from "@/util";
import deviceJobApi from "@/api/deviceJob";
import runModelCodeApi from "@/api/device/runModelCode"
export default {
  name: "DeviceList",
  mounted() {
    //加载设备列表
    this.queryDevices();
    //加载当前用户创建的任务列表
    this.queryJobList();
  },
  watch:{

  },
  computed: {
    viewDevices() {
      let devicesList = this.devices;
      if (this.keyWord.deviceSerialNo) {
        devicesList = devicesList.filter((item) =>
            item.deviceSerialNo.includes(this.keyWord.deviceSerialNo)
        );
      }
      if (this.keyWord.deviceName) {
        devicesList = devicesList.filter((item) =>
            item.deviceName.includes(this.keyWord.deviceName)
        );
      }
      if (this.keyWord.autoRun) {
        devicesList = devicesList.filter(
            (item) => item.autoRun === this.keyWord.autoRun
        );
      }
      if (this.keyWord.autoCircle) {
        devicesList = devicesList.filter(
            (item) => item.autoCircle === this.keyWord.autoCircle
        );
      }
      return devicesList;
    },
    //cron表达式的计算
    cron(){
      let week = "*"
      let mounth = "*"
      let day = "*"
      let hour = "*"
      let minute = "*"
      let second = "*"

      //判断Type
      if (this.runModel.type > 0){
        if (this.runModel.mounth && this.runModel.type >= 5){
          mounth = this.runModel.mounth
        }
        if (this.runModel.day && this.runModel.type >= 4){
          day = this.runModel.day
        }
        if (this.runModel.hour && this.runModel.type >= 3){
          hour = this.runModel.hour
        }
        if (this.runModel.minute && this.runModel.type >= 2){
          minute = this.runModel.minute
        }
        if (this.runModel.second && this.runModel.type >= 1){
          second = this.runModel.second
        }
      }
      return second + " " + minute + " " + hour + " " + day + " " + mounth + " " + week
    }
  },
  data() {
    return {
      keyWord: {
        deviceSerialNo: "",
        deviceName: "",
        autoRun: "",
        autoCircle: "",
      },
      //设备任务编辑dialog
      editDialog: {
        showDiaLog: false,
        saveDeviceJobButtonLoading:false,
        deviceSerialNo:'',
        deviceJobList:[],
        executeType:'手动',
        executeTime:''
      },
      //运行模式控制dialog
      runModel:{
        showDialog:false,
        row:null,
        type:0,
        mounth:'',
        day:'',
        hour:'',
        minute:'',
        second:''
      },
      constant: {
        RunStatus: [
          { label: "自动", value: "AUTO" },
          { label: "手动", value: "HAND" },
        ],
        CircleStatus: [
          { label: "自动", value: "AUTO" },
          { label: "手动", value: "HAND" },
        ],
      },
      jobList: [],
      renderFunc(h, option) {
        return (
            <span>
            {option.key} - {option.label}
          </span>
        );
      },
      devices: [],
    };
  },
  methods: {
    queryDevices() {
      deviceApi.selDevices().then((res) => {
        if (res && res.code === 200) {
          this.devices = res.list;
        }
        //console.log("查询到的设备列表为：", this.devices);
      });
    },
    //加载任务列表
    queryJobList(){
      let param = {}
      param.pageSize = 1000
      jobListApi.queryJobList(param).then((res)=>{
        if(res && res.total > 0){
          //this.jobList = res.records
          for (let i = 0; i < res.records.length; i++) {
            let item = {key: i,label:res.records[i].jobName, jobId: res.records[i].id}
            this.jobList.push(item)
          }
        }else{
          console.log('任务列表查询失败');
        }
      })
    },
    showEditDialog(row, editType) {
      this.editDialog.showDiaLog = true;
      this.editDialog.deviceSerialNo = row.deviceSerialNo;
      //设备已配置的任务回显动作
      let param = {}
      param.deviceSerialNo = row.deviceSerialNo
      param.jobIdList = []
      deviceJobApi.search(param).then((res)=>{
        if (res && res.code === 200 && res.list.length > 0){
          for (let i = 0; i < res.list.length; i++) {
            //渲染右侧已绑定的任务列表
            //通过jobId找到任务列表的key，  *** 注意，穿梭框是根据原始数据的key进行展示的,所以这里需要做个映射
            let key = this.jobList.filter(jobItem => jobItem.jobId === res.list[i].jobId)[0].key
            this.editDialog.deviceJobList.push(key)
            //console.log('右侧列表的数据：',this.editDialog.deviceJobList)
            //将左侧重复的任务移除
            //测试时是可以自动将数据移动到右侧, 所以这里暂时不做处理
          }
        }
      })
    },
    showRunModelDialog(row){
      this.runModel.row =  row
      this.runModel.type = runModelCodeApi.turnCode(row.runModel,row.cron)
      if (this.runModel.type && this.runModel.type > 0){
        //解析cron
        let cronArr = row.cron.split(' ')
        this.runModel.mounth = cronArr[4] === '*' ? '' : cronArr[4]
        this.runModel.day = cronArr[3] === '*' ? '' : cronArr[3]
        this.runModel.hour = cronArr[2] === '*' ? '' : cronArr[2]
        this.runModel.minute = cronArr[1] === '*' ? '' : cronArr[1]
        this.runModel.second = cronArr[0] === '*' ? '' : cronArr[0]
      }
      this.runModel.showDialog = true
    },
    saveEdit() {
      deviceApi.editDevice(this.editDialog.row).then((res) => {
        if (res && res.code === 200) {
          this.$message({
            type: "success",
            message: "设备信息更新完成",
          });
          this.handleEditDialogClose();
          this.queryDevices();
        } else {
          this.$message.error("设备信息更新异常：", res.msg);
        }
      });
    },
    handleEditDialogClose() {
      this.editDialog.showDiaLog = false;
      this.editDialog.deviceSerialNo = '';
      this.editDialog.deviceJobList = [];
    },
    handleRunModelDialogClose(){
      this.runModel.deviceSerialNo = ''
      this.runModel.type = 0
      this.runModel.mounth = ''
      this.runModel.day = ''
      this.runModel.hour = ''
      this.runModel.minute = ''
      this.runModel.second = ''
      this.runModel.showDialog = false
    },
    //设备运行状态更新动作
    changeAutoStatus(row,type) {
      if (type === "RUNMODEL") {
        row.runModel = runModelCodeApi.turnEnum(this.runModel.type)
        if(row.runModel === 'CRON' && this.cron === '* * * * * *'){
          this.$message.error("请完善cron表达式")
          return false
        }else if (row.runModel === 'CRON'){
          row.cron = this.cron
        }
      } else {
        if (row.autoCircle === "HAND") {
          row.autoCircle = "AUTO";
        } else {
          row.autoCircle = "HAND";
        }
      }
      deviceApi.editDevice(row).then((res) => {
        if (res && res.code === 200) {
          this.$message({
            type: "success",
            message: "设备信息更新完成",
          });
          this.queryDevices();
          //关闭dialog
          this.runModel.showDialog = false
        } else {
          this.$message.error("设备信息更新异常：", res.msg);
        }
      });
    },
    //将设备任务开关置为true
    touchSwitch(row){
      console.log('row:',row)
      let param = {}
      param.account = row.account
      param.deviceSerialNo = row.deviceSerialNo
      param.switchMark = true

      deviceApi.touchSwitch(param).then((res)=>{
        console.log('res',res)
        if (res && res.code === 200){
          this.$message.success("开始执行任务！")
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //穿梭框值变化时的回调
    handleChange(value, direction, movedKeys) {
      //console.log(value, direction, movedKeys);
      //console.log(this.editDialog.deviceJobList)
    },
    //保存穿梭框的值(设备任务映射)
    async saveDeviceJob(){
      //根据右侧已选择的任务列表 -> 筛选出真实的任务id
      //console.log('已选中的数据序号:',this.editDialog.deviceJobList)
      if (this.editDialog.deviceJobList && this.editDialog.deviceJobList.length > 0){
        //按钮加载状态，防止重复点击
        this.editDialog.saveDeviceJobButtonLoading = true
        let jobIdList = []
        for (let i = 0; i < this.editDialog.deviceJobList.length; i++) {
          const jobItem = this.jobList.filter(jobItem => jobItem.key === this.editDialog.deviceJobList[i])[0]
          jobIdList.push(jobItem.jobId)
        }
        //需要保存的任务集合为：
        // console.log('需要保存的任务集合为:',jobIdList)
        let param = {}
        param.deviceSerialNo = this.editDialog.deviceSerialNo
        param.jobIdList = jobIdList
        await deviceJobApi.edit(param).then((res)=>{
          if (res && res.code === 200){
            this.$message({
              type:'success',
              message:'任务列表配置完成'
            })
            this.handleEditDialogClose();
          }else {
            this.$message.error('任务列表配置失败',res.msg)
          }
        })
      }else {
        console.log('全部任务数据集:',this.jobList)
        //清空当前设备的全部任务
        let param = {}
        param.deviceSerialNo = this.editDialog.deviceSerialNo
        param.jobIdList = []
        await deviceJobApi.clean(param).then((res)=>{
          if (res && res.code === 200){
            this.$message({
              type:'success',
              message:'任务列表清空完成'
            })
            this.handleEditDialogClose();
          }else {
            this.$message.error('任务列表清空失败',res.msg)
          }
        })
      }
      this.editDialog.saveDeviceJobButtonLoading = false
    },
    //根据颜色渲染行
    tableRowClassName({ row, rowIndex }) {
      if (row.status === "OFFLINE") {
        return "warning-row";
      } else if (row.status === "DEVICE") {
        return "";
      } else {
        return "";
      }
    },
    parseTime(target) {
      return util.parseTime(target);
    },
  },
};
</script>
<style scoped>
.container {
  display: flex; /* 启用Flexbox */
  flex-direction: column; /* 设置主轴方向为垂直方向 */
  align-items: center; /* 子元素在交叉轴（这里是水平方向）上居中 */
  justify-content: center; /* 子元素在主轴（这里是垂直方向）上居中，但在这个场景下可能不需要，因为我们想要的是上下排列 */
  height: 90vh; /* 假设你想要容器占满视窗高度 */
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: green;
  font-weight: bold;
}
.clickable-span:hover {
  cursor: pointer;
}
</style>