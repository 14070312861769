<template>
    <div>
        <h3>UserCenter</h3>
    </div>
</template>
<script>
export default {
    name:'UserCenter',
}
</script>
<style>

</style>