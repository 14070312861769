import Vue from 'vue'
import App from './App.vue'
//导入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css'; 

//引入前置路由守卫
//import '@/auth'

//配置router
import router from './router'
//配置vuex
import store from './store';


Vue.config.productionTip = false

//全局引入ElementUI
Vue.use(ElementUI)

new Vue({
  //注册router
  router,
  //注册vuex
  store,
  render: h => h(App),
}).$mount('#app')
