<template>
  <div class="container">
    <div style="margin-top: 20px; width: 90%">
      <el-form label-width="100px">
        <el-row :gutter="1">
          <el-col :span="5">
            <el-form-item label="任务名称:">
              <el-input
                placeholder="请输入任务名"
                v-model="keyWord"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" @click="queryListButton">查询</el-button>
            <el-button type="success" @click="openCreateJobDialog">
              创建任务
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="width: auto; margin-top: 20px">
      <el-table :data="jobList" border style="width: 100%">
        <el-table-column type="index" width="60"></el-table-column>
        <el-table-column label="任务名称" width="150">
          <template slot-scope="scope">
            <a
              style="cursor: pointer; color: deepskyblue"
              @click="editJob(scope.row)"
              >{{ scope.row.jobName }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="jobDec"
          label="任务描述"
          width="360"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="任务类型"
          width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type === 'T'">测试</span>
            <span v-else-if="scope.row.type === 'F'">正式</span>
            <span v-else-if="scope.row.type === 'D'">禁用</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--          prop="status"-->
<!--          label="任务状态"-->
<!--          width="150"-->
<!--        ></el-table-column>-->
        <el-table-column
          prop="circulate"
          label="完整循环次数"
          width="180"
        ></el-table-column>
        <el-table-column label="任务创建时间" width="200">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.createdTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="任务最近修改时间" width="200">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.updatedTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 'UNDISABLE'"
              size="medium"
              type="info"
              @click="updateJobStatus(scope.row)"
              >禁用</el-button
            >
            <el-button
              v-else
              size="medium"
              type="success"
              @click="updateJobStatus(scope.row)"
              >启用</el-button
            >
            <el-button
              size="medium"
              type="danger"
              @click="removeThisJob(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Page
          :pageCurrent="page.pageCurrent"
          :pageSize="page.pageSize"
          :pageSizes="page.pageSizes"
          :total="page.total"
          @pageSizeChange="pageSizeChange"
          @pageCurrentChange="pageCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      title="创建新任务"
      :visible="createJobDialog"
      width="35%"
      :before-close="dialogClose"
    >
      <el-form label-width="100px">
        <el-form-item label="任务名称：">
          <el-input
            v-model="newJobForm.jobName"
            style="width: 95%"
            maxlength="15"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="描述：">
          <el-input
            v-model="newJobForm.jobDec"
            style="width: 95%"
            maxlength="255"
            show-word-limit
            type="textarea"
            :rows="2"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button size="medium" type="success" @click="createJobMethod"
          >创建</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import jobListApi from "@/api/jobList";
import util from "@/util";
import Page from "@/components/Page.vue";
export default {
  name: "JobList",
  components: { Page },
  data() {
    return {
      keyWord: "",
      page: {
        pageSizes: [10, 20, 50, 100],
        pageCurrent: 1,
        pageSize: 10,
        total: 0,
      },
      createJobDialog: false,
      //任务列表
      jobList: [],

      //新任务信息
      newJobForm: {
        jobName: "",
        jobDec: "",
      },

      constant: {
        type: [
          { id: "T", value: "测试" },
          { id: "F", value: "正式" },
          { id: "D", value: "禁用" },
        ],
      },
    };
  },
  mounted() {
    //加载当前登录用户的任务列表
    this.queryList();
  },
  methods: {
    queryListButton() {
      this.page.pageCurrent = 1;
      this.queryList();
    },
    queryList() {
      let param = {};
      param.jobName = this.keyWord;
      param.pageCurrent = this.page.pageCurrent;
      param.pageSize = this.page.pageSize;
      jobListApi.queryJobList(param).then((res) => {
        if (res && res.records.length > 0) {
          this.jobList = res.records;
          this.page.total = res.total;
        }
        //console.log("@pageList:,", res);
      });
    },
    parseTime(target) {
      return util.parseTime(target);
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.queryList();
    },
    pageCurrentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.queryList();
    },
    //打开dialog框
    openCreateJobDialog() {
      this.createJobDialog = true;
    },
    //创建任务
    createJobMethod() {
      jobListApi.registerJob(this.newJobForm).then((res) => {
        console.log("@res,", res);
        if (res && res.code === 200) {
          //关闭dialog框
          this.dialogClose();
          this.queryList()
          this.$message({
            message: "任务注册成功",
            type: "success",
          });
        }
      });
    },
    dialogClose() {
      this.createJobDialog = false;
      this.newJobForm = { jobName: "", jobDec: "" };
    },
    editJob(row) {
      console.log("@row:", row);
      //跳转到编辑页面
      this.$router.push({
        name: "ViewAndEditJob",
        query: { jobId: row.id },
      });
    },
    updateJobStatus(row) {
      console.log("row", row.id);
      let param = {};
      param.id = row.id;
      jobListApi.updateJobStatus(param).then((res) => {
        console.log("@Res:", res);
        if (res && res.code === 200) {
          this.$message({
            message: "状态更新完成",
            type: "success",
          });
          //刷新数据列表
          this.queryListButton();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    removeThisJob(row) {
      let param = {};
      param.id = row.id;
      jobListApi.remove(param).then((res) => {
        console.log("res", res);
        if (res && res.code === 200) {
          this.$message({
            message: "指定任务移除完成",
            type: "success",
          });
          //刷新数据列表
          this.queryListButton();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style scope>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>