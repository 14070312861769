import Vue from 'vue';  
import VueRouter from 'vue-router';  
import login from '@/views/login/login'; 
import home from '@/views/home/home';
import deviceList from '@/views/deviceList/deviceList';
import jobList from '@/views/jobList/jobList';
import messageCenter from '@/views/messageCenter/messageCenter';
import myFeedback from '@/views/myFeedback/myFeedback';
import userCenter from '@/views/userCenter/userCenter';
import userControl from '@/views/userControl/userControl';
import viewAndEditJob from '@/components/viewAndEditJob.vue';
import CommandEdit from '@/views/commandEdit/commandEdit.vue';
  
Vue.use(VueRouter);  
  
const routes = [
  {
    path: '/',
    redirect: '/login', // 将根路径重定向到 /login
  },
  {  
    path: '/login',  
    name: 'login',
    component: login
  },
  {  
    path: '/home',  
    name: 'Home',  
    component: home
  },  
  {  
    path: '/jobList',  
    name: 'JobList',  
    component: jobList
  }, 
  {  
    path: '/deviceList',  
    name: 'DeviceList',  
    component: deviceList
  },  
  {  
    path: '/messageCenter',  
    name: 'MessageCenter',  
    component: messageCenter
  }, 
  {  
    path: '/userCenter',  
    name: 'UserCenter',  
    component: userCenter
  }, 
  {  
    path: '/myFeedback',  
    name: 'MyFeedback',  
    component: myFeedback
  }, 
  {  
    path: '/userControl',  
    name: 'UserControl',  
    component: userControl
  },
    //任务编辑页面
  {
    path: '/viewAndEditJob',
    name: 'ViewAndEditJob',
    component: viewAndEditJob
  },
  //命令管理页面
  {
    path: '/commandEdit',
    name: 'CommandEdit',
    component: CommandEdit
  },
];  
  
const router = new VueRouter({  
  mode: 'history', // 或者 'hash'，根据你的需要选择
  base: process.env.BASE_URL,
  scrollBehavior:()=>({y:0}),
  routes  
});
  
export default router;