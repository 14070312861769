import request from "@/api/request";


const componentsJS = {
    //获取图片流
    getImage(data) {
        return request({
            url: '/api/file/onloadTemplate',
            method: 'post',
            responseType: 'blob', // 重要：设置响应类型为blob
            data
        })
    },
}

export default componentsJS