<template>
  <div id="app">
    <!--header在login页面时不展示-->
    <Header v-if="this.$route.path !== '/login'"/>
    <!--路由出口-->
    <router-view></router-view>
  </div>
</template>

<script>
import Login from './views/login/login.vue'
import Header from './views/header/header.vue'

export default {
  name: 'App',
  components: {
    Login,
    Header
  }
}
</script>
  
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html, body {  
    margin: 0; /* 移除默认的边距 */  
    padding: 0; /* 移除默认的内边距 */  
    height: 100%; /* 使html和body的高度都扩展到整个视口的高度 */  
    width: 100%; /* 这不是必需的，因为默认就是100%，但明确设置有助于清晰表达意图 */  
  }
/* 因为导航菜单的层级是 9000， 防止message提示被修改 */
.el-message {
  z-index: 9999 !important; /* 确保这个值大于你的导航菜单的 z-index */
}
</style>
