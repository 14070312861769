<template>
    <div>
        <h3>myFeedback</h3>
    </div>
</template>
<script>
export default {
    name:'MyFeedback',
}
</script>
<style>

</style>