<template>
    <div>
        <h3>MessageCenter</h3>
    </div>
</template>
<script>
export default {
    name:'MessageCenter',
}
</script>
<style>

</style>