const util = {
    //转换时间
    formatDateToYYYYMMDD(target){
        // 将ISO 8601字符串转换为Date对象
        const date = new Date(target);

        // 使用getFullYear(), getMonth() (注意月份是从0开始的，所以需要+1), getDate()来获取年、月、日
        // 然后使用模板字符串来格式化它们
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    },

    parseTime(time, cFormat) {
        if (arguments.length === 0) {
            return null;
        }
        const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
        let date;
        if (typeof time === "undefined" || time === null || time === "null") {
            return "";
        } else if (typeof time === "object") {
            date = time;
        } else {
            if (typeof time === "string" && /^[0-9]+$/.test(time)) {
                time = parseInt(time);
            }
            if (typeof time === "number" && time.toString().length === 10) {
                time = time * 1000;
            }
            date = new Date(time);
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        };
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key];
            // Note: getDay() returns 0 on Sunday
            if (key === "a") {
                return ["日", "一", "二", "三", "四", "五", "六"][value];
            }
            if (result.length > 0 && value < 10) {
                value = "0" + value;
            }
            return value || 0;
        });
        return time_str;
    },

    //计算时间差值
    differenceTime(data_1, data_2){
        const futureDate_2 = new Date(data_2);
        const difference = data_1 - futureDate_2;

        return Math.floor((difference % (1000 * 60)) / 1000); 
    }
}

export default util