import request from "@/api/request";

const logApi = {
    //查询用户列表
    login(data){
        return request({
            url: '/api/auth/login',
            method: 'post',
            data
        })
    },
    //根据Token拉取用户信息
    currentUser(){
        return request({
            url: '/api/auth/currentUser',
            method: 'post'
        })
    },

    //注册用户
    registerUser(data){
        return request({
            url: '/api/user/registerUser',
            method: 'post',
            data
        })
    },
}

export default logApi