<template>
  <div class="top-bar">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
    >
      <el-menu-item v-if="showFlag1" index="home">工作台</el-menu-item>
      <el-menu-item v-if="showFlag1" index="deviceList">设备列表</el-menu-item>
      <el-menu-item v-if="showFlag1" index="jobList">任务列表</el-menu-item>
      <el-menu-item v-if="showFlag1" index="messageCenter">消息中心</el-menu-item>
      <el-menu-item v-if="showFlag1" index="userCenter">个人中心</el-menu-item>
      <el-menu-item v-if="showFlag1" index="myFeedback">我的反馈</el-menu-item>
      <el-menu-item v-if="showFlag1" index="userControl">用户管理</el-menu-item>
      <el-menu-item v-if="showFlag1" index="commandEdit">命令管理</el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      //默认激活的页面
      activeIndex: "home",
      showFlag1:true,
      showFlag2:false
    };
  },
  mounted(){
    //加载用户权限
    this.initUserAuth()
  },
  methods: {
    initUserAuth(){
      console.log('@initUserAuth');
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style scoped>
.top-bar{
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9000;
}
</style>