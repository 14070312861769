import logApi from "@/api/login";

//用户的小仓库
const state = {
    //当前登录用户的详细信息
    user:{},
    token:'',
}
const actions = {
    async loginUserToStore({commit}){
        //根据token加载用户详细数据
        let user = await logApi.currentUser();
        //console.log('saveLoginUser:',user);
        commit('LOGIN_USER',user)
    },
}
const mutations = {
    LOGIN_USER(state,value){
        console.log('LOGIN_USER:,',value);
        state.user = value
    },
}
const getters = {}

export default {
    state,
    actions,
    mutations,
    getters
}