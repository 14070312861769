import request from "@/api/request";

const deviceJobApi = {
    edit(data){
        return request({
            url: '/api/deviceJob/edit',
            method: 'post',
            data
        })
    },
    clean(data){
        return request({
            url: '/api/deviceJob/clean',
            method: 'post',
            data
        })
    },
    search(data){
        return request({
            url: '/api/deviceJob/search',
            method: 'post',
            data
        })
    },
}

export default deviceJobApi