<template>
  <div style="margin: 20px">
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageCurrent"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total">
    </el-pagination>
  </div>
</template>
<script>
  export default {
    name:'Page',
    props:{
      pageCurrent:{
        type: Number,
        required: true
      },
      pageSize:{
        type: Number,
        required: true
      },
      total:{
        type: Number,
        required: true
      },
      pageSizes:{
        type: Array,
        required: true
      },
    },
    data(){
      return {

      }
    },
    methods:{
      handleSizeChange(val){
        //console.log('@页容量变化：',val)
        this.$emit('pageSizeChange',val)
      },
      handleCurrentChange(val){
        //console.log('@页下标变化：',val)
        this.$emit('pageCurrentChange',val)
      },
    },
  }
</script>
<style>

</style>