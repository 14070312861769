export function analysisAdbCommand (paramList,commandTemp,command){
    if (command && paramList.length > 0){
        //console.log(commandTemp)
        //console.log(command)

        //将命令通过空格进行分隔，然后拿到不一致的数据，那么这个数据就是参数列表，模版的部分就是Key， 命令部分就是value
        let commandTempArray = commandTemp.split(" ");
        let commandArray = command.split(" ");
        if (commandTempArray.length === commandArray.length){
            for (let i = 0; i < commandTempArray.length; i++) {
                let tempItem = commandTempArray[i]
                let commandItem = commandArray[i]
                if (tempItem !== commandItem){
                    //去除参数包围${}
                    tempItem = tempItem.replace("${","")
                    tempItem = tempItem.replace("}","")
                    //console.log(tempItem)
                    //console.log(commandItem)
                    for (let j = 0; j < paramList.length; j++) {
                        if (paramList[j].name === tempItem){
                            paramList[j].value = commandItem
                            break
                        }
                    }
                }
            }
        }else {
            console.log("模版与命令不匹配")
        }
    }
    return paramList;
}