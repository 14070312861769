<template>
  <div>
    <h3>这个是首页</h3>
    <el-button @click="goLogin">点我跳转到登录页</el-button>
  </div>
</template>
<script>
import {mapState} from "vuex";
export default {
  name: "Home",
  computed:{
    ...mapState({
      nickName:(state)=>{
          return state.loginUser.user.nickName
      }
    })
  },
  watch:{
    //展示欢迎页
    nickName(newValue, oldValue){
      this.$message({
          message: '欢迎您, ' + newValue,
          type: 'success'
        });
    },
  },
  mounted(){

  },
  data() {
    return {};
  },
  methods: {
    goLogin() {
      this.$router.push("login");
    },
  },
};
</script>
<style>
</style>