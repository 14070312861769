import request from "@/api/request";

const actionApi = {
    //添加标记
    changeSerialNo(data){
        return request({
            url: '/api/action/changeSerialNo',
            method: 'post',
            data
        })
    },
}

export default actionApi