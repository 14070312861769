<template>
  <div>
    <!-- <img :src="imageUrl" /> -->
    <div class="demo-image">
      <div class="block">
        <el-image
          style="width: 100px; height: 100px"
          :src="imageUrl"
          :preview-src-list="srcPic"
          fit="contain"
        ></el-image>
      </div>
    </div>
  </div>
</template>
<script>
import componentsJS from "@/api/components";
export default {
  name: "ImageView",
  props: {
    fileId: {
      type: Number,
      require: true,
    },
  },
  created() {
    //加载图像
    this.getImages();
  },
  watch: {
    fileId() {
      console.log("fileId发生变化");
      this.getImages();
    },
  },
  data() {
    return {
      imageUrl: "",
      srcPic:[],
    };
  },
  methods: {
    //加载动作模版图像
    getImages() {
      //console.log('子组件方法触发:',this.fileId)
      let param = {};
      param.id = this.fileId;
      componentsJS.getImage(param).then((res) => {
        // console.log('imageRes:',res)
        let blob = new Blob([res], { type: "image/png" });
        this.imageUrl = URL.createObjectURL(blob);
        this.srcPic = [URL.createObjectURL(blob)]; //大图预览
      });
    },
  },
};
</script>
<style scoped>
</style>