<template>
  <div class="container">
    <el-form class="form-container" :model="loginForm" :rules="rules">
      <h2>后台管理系统</h2>
      <div style="display: flex; flex-direction: column">
        <el-form-item prop="account">
          <el-input
            v-model.trim="loginForm.account"
            style="width: 350px"
            placeholder="用户名"
          >
            <template slot="prepend"
              ><i class="el-icon-user-solid"></i
            ></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            style="width: 350px"
            placeholder="密码"
            type="password"
            v-model.trim="loginForm.password"
          >
            <template slot="prepend"><i class="el-icon-lock"></i></template>
          </el-input>
        </el-form-item>
      </div>
      <el-button
          type="success"
          round
          style="width: 350px"
          @click="loginMethod"
          :loading="loginButtonLoading"
        >登录</el-button
      >
    </el-form>
  </div>
</template>
<script>
import logApi from "@/api/login";
export default {
  name: "Login",
  data() {
    return {
      //登录按钮状态
      loginButtonLoading: false,
      //表单校验
      rules: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 10,
            message: "密码长度应大于6个字符",
            trigger: "blur",
          },
        ],
      },
      loginForm: {
        account: "",
        password: "",
      },
    };
  },

  methods: {
    async loginMethod() {
      this.loginButtonLoading = true
      logApi.login(this.loginForm).then((res) => {
        console.log("@loginRes", res);
        if (res && res.loginCode === 10000) {
          this.loginButtonLoading = false
          //登录成功，token保存到localStorage
          localStorage.setItem("Authorization", res.token);
          //拉取用户完整数据，如果拉取成功，那么跳转到home页面
          //将完整用户数据保存到vuex
          console.log("VueX:", this.$store);
          this.$store.dispatch("loginUserToStore");
          //跳转到主页
          this.$router.push("home");
        } else if (res && res.loginCode === 10002) {
          this.loginButtonLoading = false
          this.$message.error(res.loginMsg);
        } else {
          this.$message({
            message: '-Code:' + res.code + '-Msg:' + res.loginMsg,
            type: "warning",
          });
        }
      }).catch((e)=>{
        console.log('登录异常：',e)
        this.loginButtonLoading = false
      })
    },
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(~@/assets/login/background_11.jpg) no-repeat center center
    fixed;
  /* background-color: rgb(234, 236, 239); */
  background-size: 100% 100%;
}

.form-container {
  /* 根据需要调整el-form的样式 */
  margin: 0; /* 重置边距 */
  /* 其他样式 */
}
h2 {
  color: white; /* 半透明的蓝色 */
}
</style>
